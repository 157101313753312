import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-full mt-32 ">
      <h3 className="text-3xl text-primary text-center">Privacy Policy</h3>
      <h6 className="text-center text-slate-600">Last updated December 2024</h6>
      <div className="p-9">
        <p>Welcome to Tipkit!</p>
        <p>
          Your privacy is important to Tipkit LLC (the “Company”, “we”, or
          “us”), and we are committed to adhering to federal and state privacy
          laws and industry guidelines regarding the collection, use, and
          disclosure of user information through the website and mobile
          applications.
        </p>
        <p>This Privacy Policy includes:</p>
        <ul className="list-disc ml-5">
          <li>
            <strong>Information Collection:</strong> Tipkit collects business
            and/or personal information (e.g., name, contact details) and usage
            information (e.g., browser type, IP address) through various means,
            including cookies and web beacons.
          </li>
          <li>
            <strong>Information Use:</strong> This information is used to
            provide and improve services, personalize and customize user
            experiences, process transactions, and communicate with users.
          </li>
          <li>
            <strong>Information Sharing:</strong> Tipkit may share
            non-personally identifiable information with third parties for
            analysis and advertising purposes. Personal and business information
            is shared with third-party service providers only for necessary
            purposes and with user consent for direct marketing.
          </li>
          <li>
            <strong>User Choices:</strong> Users can opt-out of targeted
            advertising and modify their communication preferences.
          </li>
        </ul>
        <p>
          This Privacy Policy covers all our online (e.g., web and mobile
          services, Mobile Applications) and offline activities owned, operated,
          provided, or made available by the Company. This includes interactive
          features or downloads that (i) we own or control, (ii) are available
          through the Services, or (iii) interact with the Services and
          incorporate this Privacy Policy.
        </p>
        <p>
          Please review this Privacy Policy carefully. If you do not agree to
          this Privacy Policy, do not use our Services or give us any of your
          information. Additionally, review our Terms of Use, which may apply to
          your use of our websites and mobile applications. If you have
          questions about this Privacy Policy, please contact us at{" "}
          <a href="mailto:hello@tipkit.co" className="text-blue-500 underline">
            hello@tipkit.co
          </a>
          .
        </p>
        <h5 className="text-lg font-semibold my-3">Personal Information</h5>
        <p>
          Although the personal and business information we may collect through
          our Website is limited and voluntary, you are responsible for
          providing the Company with accurate and complete information and
          contacting the Company for corrections. Information may be collected
          through various forms, including account registration, user profiles,
          and interactions with us.
        </p>
        <p>
          Usage Information, such as browser type, URLs, geolocation, and Device
          Identifiers, may be collected automatically. This information is used
          to enhance and improve the Services. When associated with personal or
          business information, Usage Information will be treated as such.
        </p>
        <h6 className="text-md font-semibold my-2">Cookies</h6>
        <p>
          Cookies, log files, and pixel tags (web beacons) are used to track
          user behavior and preferences. You can disable cookies in your browser
          settings, but doing so may affect the functionality of the Services.
        </p>
        <h6 className="text-md font-semibold my-2">Web Beacons</h6>
        <p>
          Web beacons are small graphics embedded in web pages to track user
          activity. They help us manage content effectively and are not tied to
          personal information.
        </p>
        <h6 className="text-md font-semibold my-2">Embedded Scripts</h6>
        <p>
          Embedded scripts collect information about user interactions with the
          Services and are active only during your connection to the Services.
        </p>
        <h6 className="text-md font-semibold my-2">
          Information Third Parties Provide About You
        </h6>
        <p>
          We may supplement the information we collect with data from third
          parties to enhance your experience. This information is treated as
          personal or business information unless otherwise disclosed.
        </p>
        <h6 className="text-md font-semibold my-2">
          Information Collected by Mobile Applications
        </h6>
        <p>
          Mobile Applications may collect device-specific information, such as
          IP address, geolocation, and operating system details, to improve the
          Services and personalize your experience.
        </p>
        <h6 className="text-md font-semibold my-2">
          Use of Collected Information
        </h6>
        <p>
          Collected information is used to verify accounts, respond to
          inquiries, troubleshoot, and improve services. We may also send
          service-related announcements when necessary.
        </p>
        <h6 className="text-md font-semibold my-2">
          Disclosure of Information to Third Parties
        </h6>
        <p>
          Non-personally identifiable information may be shared for industry
          analysis and targeted advertising. Personal or business information
          will not be shared without user consent.
        </p>
        <h6 className="text-md font-semibold my-2">Contests and Promotions</h6>
        <p>
          Participation in contests or promotions may require personal
          information disclosure. This information will be used in accordance
          with the promotion’s rules and applicable privacy policies.
        </p>
        <h6 className="text-md font-semibold my-2">
          Administrative and Legal Reporting Reasons
        </h6>
        <p>
          We may disclose information to comply with legal obligations, protect
          public safety, or investigate potential fraud or security issues.
        </p>
        <h6 className="text-md font-semibold my-2">User-Generated Content</h6>
        <p>
          Publicly shared content, such as blogs or forums, may be accessible by
          others. Please consider your privacy before sharing information.
        </p>
        <h6 className="text-md font-semibold my-2">
          Modifications and Account Management
        </h6>
        <p>
          You can update or delete your account information by contacting us.
          Even after account termination, some data may be retained to comply
          with legal obligations.
        </p>
        <h6 className="text-md font-semibold my-2">Age-Appropriate Access</h6>
        <p>
          Users must be 18 years or older to use the Services. Information from
          minors under 18 will be deleted upon discovery.
        </p>
        <h6 className="text-md font-semibold my-2">
          Protection of Information
        </h6>
        <p>
          We implement industry-standard security measures, including encryption
          and firewalls, to protect your data. However, no system is completely
          secure.
        </p>
        <h6 className="text-md font-semibold my-2">Privacy Policy Updates</h6>
        <p>
          This Privacy Policy may be updated periodically. Continued use of the
          Services constitutes acceptance of the updated policy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
